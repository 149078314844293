
define('template!ordersDetailSupplierEditProductPrintZoneView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="header">\n  <div class="print-zone">\n    <div class="name-ctn">\n      <div class="name">' +
((__t = ( printAreaName )) == null ? '' : __t) +
'</div>\n      ';
 if(productOwner) { ;
__p += '\n        <div class="product-owner-ctn">\n          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">\n            <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z"/>\n          </svg>\n          <div class="product-owner">' +
((__t = ( _.i18n('printZone.printedBy') + ' ' + productOwner )) == null ? '' : __t) +
'</div>\n        </div>\n      ';
 } ;
__p += '\n    </div>\n    <div class="print">' +
((__t = ( print )) == null ? '' : __t) +
'</div>\n    <div class="size">' +
((__t = ( size )) == null ? '' : __t) +
'</div>\n    ';
 if (colorModeType === 'spot') { ;
__p += '\n        <div class="colors">' +
((__t = ( colors )) == null ? '' : __t) +
':</div>\n    ';
 } ;
__p += '\n    <div class="colors-region"></div>\n  </div>\n  <div class="buttons">\n    <svg class="zoom-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM184 296c0 13.3 10.7 24 24 24s24-10.7 24-24V232h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H232V120c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z"/></svg>\n    ';
 if(imageUrl !== '') { ;
__p += '\n      <svg class="download-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>\n    ';
 } ;
__p += '\n  </div>\n</div>\n<div class="body">\n  <div class="image-region"></div>\n</div>';

}
return __p
};});

