define('mailsListMailLabelsView',[
  'module',
  'app',
  'backbone',
  'underscore',
  'marionette',
  'mailsListMailLabelView'
], function (
  Module,
  App,
  Backbone,
  _,
  Marionette,
  LabelsView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    className: 'labels-view',
    childView: LabelsView,

    modelEvents: {
      'change:labels': 'fetch'
    },

    initialize: function() {
      this.collection = new Backbone.Collection();
      this.fetch();
    },

    fetch: function() {
      this.collection.reset();

      _.each(this.model.get('labels'), function(label) {
        this.collection.add(new Backbone.Model({name: label}));
      }, this);
    }
  });
});
