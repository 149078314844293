define('ordersDetailSupplierEditProductPrintZoneImageView',[
  'module',
  'marionette',
  'underscore',
  'template!ordersDetailSupplierEditProductPrintZoneImageView'
], function (
  Module,
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,    
    className: 'image-view',

    ui: {
      canvasContainer: '.canvas-ctn',
      canvas: '.canvas-ctn .canvas',
      loader: '.loader-2',
      zoomButton: '.zoom-button',
      downloadButton: '.download-button'
    },

    modelEvents: {
      'change:arrayBuffer': 'render'
    },
    
    onRender: function () {
      if(!this.model.get('files'))
        return;

      if(this.model.get('files').length === 0)
        return;

      var file = this.model.get('files')[0];

      if (file.content) {
        // Loaded via <script> tag, create shortcut to access PDF.js exports.
        var pdfjsLib = window['pdfjs-dist/build/pdf'];

        // The workerSrc property shall be specified.
        pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.228/pdf.worker.min.js';

        // Asynchronous download of PDF
        var dataURI = 'data:application/pdf;base64, ' + file.content;

        var loadingTask = pdfjsLib.getDocument(dataURI);
        loadingTask.promise.then(_.bind(function (pdf) {
          // Fetch the first page
          var pageNumber = 1;
          pdf.getPage(pageNumber).then(_.bind(function (page) {
            this.ui.loader.hide();

            var scale = 2;

            var viewport = page.getViewport({scale: scale});

            // Prepare canvas using PDF page dimensions
            var canvas = this.ui.canvas[0];
            var context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            var renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            var renderTask = page.render(renderContext);
            renderTask.promise.then(function () {
              console.log('Page rendered');
            });

            this.ui.canvasContainer.css('display', 'flex');
          }, this));
        }, this), _.bind(function (reason) {
          console.error(reason);
        }, this));
      }
    }
  });
});
  
