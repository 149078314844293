define('ordersListImageView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'settings',
  'windows',
  'galleryView',
  'tableFieldView',
  'template!ordersListImageView',
  'entities/product'
], function (
  App,
  Module,
  Backbone,
  _,
  Settings,
  Windows,
  GalleryView,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'orders-list-image-view table-field-view',

    ui: {
      image: 'img'
    },

    modelEvents: {
      'change:snapshotsUids': 'render'
    },

    events: {
      'click': 'onClick'
    },

    serializeData: function() {
      var data = {};
      if(this.model.get('snapshotsUids'))
        data.src = Settings.url('file', this.model.get('snapshotsUids'), {size: 'thumbnail'});
      else
        data.src = Settings.get('notFoundImage');
      return data;
    },

    onRender: function() {
      this.ui.image.on('error', _.bind(function() {
        this.ui.image.css('display', 'none');
      }, this));
    },

    onClick: function () {
      var model = new Backbone.Model({urls: []});
      GalleryView.prototype.open(
          'orders.product.gallery.' + this.model.get('purchaseOrderId'),
          _.i18n('orders.product.gallery.title') + ': ' + this.model.get('purchaseOrderId') + ' | ' + this.model.get('productName'),
          model);
      this.fetch().done(_.bind(function (productModel) {
        model.set('urls', this.model.getImageUrls(productModel));
      }, this));
    },

    fetch: function() {
      var defer = $.Deferred();
      var productModel = null;
      if (this.model.get('pim')) {
        productModel = App.request('products:model', {code: this.model.get('productBaseCode')});
        productModel.fetchByCode(this.model.get('pim')).done(_.bind(function () {
          defer.resolve(productModel);
        } ,this));
      } else {
        productModel = App.request('products:model', {code: this.model.get('productBaseCode')});
        productModel.fetchByCode(this.model.get('pim')).done(_.bind(function () {
          defer.resolve(productModel);
        } ,this));
      }
      return defer.promise();
    }
  });
});
