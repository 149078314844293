define('ordersDetailPriceV2View',[
  'app',
  'module',
  'marionette',
  'jquery',
  'underscore',
  'ordersDetailPriceV2PricesView',
  'ordersDetailPriceV2PrintPricesView',
  'template!ordersDetailPriceV2View'
], function (
  App,
  module, 
  Marionette,
  $,
  _,
  PricesView,
  PrintView,
  Tpl
) {
  'use strict';

  module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'order-detail-price-view',

    regions: {
      priceRegion: '.price-region',
      printPriceRegion: '.print-price-region'
    },

    ui: {
      loading: '.loading'
    },
    
    onShow: function() {
      this.listenTo(this, 'get-price-for-order', _.bind(this.renderContent, this));

      this.renderContent();
    },

    renderContent: function () {

      this.showLoading(true);

      $.when(App.request('caccount:get-factors')).done(_.bind(function (factorsV2) {
        $.when(App.request('caccount:get-factors-by-supplier-id', this.model.get('manufacturerId')))
          .done(_.bind(function (factorsV2Supplier) {
            if (factorsV2Supplier) {
              factorsV2 = factorsV2Supplier;
            }
            this.model.getPrice(true, true).done(_.bind(function (currentPrices) {
              this.model.getPrice(true, false).done(_.bind(function (currentRsPrices) {
                this.model.getPrice(false, true).done(_.bind(function (prices) {
                  this.model.getPrice(false, false).done(_.bind(function (rsPrices) {
                    this.showLoading(false);

                    if (factorsV2Supplier)
                      factorsV2 = factorsV2Supplier;
          
                    this.pricesView = new PricesView({
                      model: this.model,
                      factorsV2: factorsV2,
                      currentPrices: currentPrices,
                      currentRsPrices: currentRsPrices,
                      prices: prices,
                      rsPrices: rsPrices,
                      product: this.options.product,
                      test: 'sdfsdf'
                    });
                    this.pricesView.listenTo(this.pricesView, 'get-price-for-order', _.bind(this.renderContent, this));
                    this.pricesView.listenTo(this.pricesView, 'show-loading', _.bind(this.showLoading, this));
                    this.pricesView.listenTo(this.pricesView, 'refresh', _.bind(this.renderContent, this));
                    this.getRegion('priceRegion').show(this.pricesView);
          
                    this.printView = new PrintView({
                      model: this.model,
                      factorsV2: factorsV2,
                      currentPrices: currentPrices,
                      currentRsPrices: currentRsPrices,
                      prices: prices,
                      rsPrices: rsPrices,
                      product: this.options.product
                    });
                    this.getRegion('printPriceRegion').show(this.printView);                    
                  }, this));
                }, this));
              }, this));
            }, this));
          }, this));
      }, this)); 
    },

    showLoading: function(show) {
      if (show) {
        this.ui.loading.show();
      } else {
        this.ui.loading.hide();
      }
    }
  });
});
