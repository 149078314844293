define('mailsMailLabelView',[
  'module',
  'windows',
  'marionette',
  'underscore',
  'template!mailsMailLabelView'
], function (
  Module,
  Windows,
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,    
    className: 'label-view',

    events: {
      'click': 'onClick'
    },

    serializeData: function () {
      return {
        value: this.model.get('name')
      };
    },

    onClick: function() {
      this.trigger('delete');
    }
  });
});
  
