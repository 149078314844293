define('mailsMailLabelsAddView',[
  'app',
  'module',
  'underscore',
  'modalsConfirmationView',
  'template!mailsMailLabelsAddView'
], function(
  App,
  Module,
  _,
  ModalsConfirmationView,
  Tpl
) {
  'use strict';

  Module.exports = ModalsConfirmationView.extend({
    template: Tpl,
    className: 'mails-mail-labels-add-view ' + ModalsConfirmationView.prototype.className,

    ui: _.extend({
      input: 'input'
    }, ModalsConfirmationView.prototype.ui),

    onConfirm: function () {
      if(this.ui.input.val() === '')
        return;

      var value = this.ui.input.val();
      this.model.addLabel(this.options.mailSecId, this.model.get('folder'), value).done(_.bind(function() {
        var labels = this.model.get('labels').slice();
        labels.push(value);
        this.model.set('labels', labels);
      }, this));
      this.windowView.close();
    },

    // Static methods
    open: function (data) {
      require([
        'windows',
        'mailsMailLabelsAddView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View(data);
          }, this),
          modal: true,
          width: 400,
          maxWidth: 800,
          title: _.i18n('mails.labels.add')
        });
      });
    }
  });
});
