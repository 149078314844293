define('webshopProductsDetailBodySlugsLanguageHistoryView',[
  'module',
  'backbone',
  'underscore',
  'app',
  'tableView',
  'webshopProductsDetailBodySlugsLanguageHistoryToolsView',
  'entities/webshop/products'
], function (
    Module,
    Backbone,
    _,
    App,
    TableView,
    ToolsView
) {
  'use strict';

  Module.exports = TableView.extend({
    className: 'webshop-products-detail-body-slugs-language-history-view ' + TableView.prototype.className,
    pageSize: 20,
    pageMode: 'pagination',
    setFields: function() {

      var fields = [];

      fields.push({
        name: 'Slug',
        className: 'slug',
        bind: 'slug'
      });

      fields.push({
        name: 'Tools',
        className: 'tools',
        view: ToolsView
      });

      return fields;
    },

    fetch: function(first, pageSize) {
      var defer = $.Deferred();

      var params = {};
      params.pageSize = pageSize;
      params.first = first;
      params.lang = this.options.lang;
      params.excludeCurrentSlug = true;

      if(this.fetchRequest)
        this.fetchRequest.abort();

      this.fetchRequest = $.ajax({
        type: 'GET',
        url: App.request('webshop.product.history', this.model.getCode(), params),
        success: _.bind(function (items) {
          var models = [];
          _.each(items, _.bind(function(item) {
            var model = new Backbone.Model(item);
            models.push(model);
          }, this));
          defer.resolve(models);
        }, this)
      });

      return defer.promise();
    },

    fetchCount: function() {
      var defer = $.Deferred();

      var params = {};
      params.lang = this.options.lang;
      params.excludeCurrentSlug = true;

      if(this.fetchCountRequest)
        this.fetchCountRequest.abort();

      this.fetchCountRequest = $.ajax({
        type: 'GET',
        url: App.request('webshop.product.history.count', this.model.getCode(), params),
        success: _.bind(function (count) {
          defer.resolve(count);
        }, this)
      });

      return defer.promise();
    }
  });
});
