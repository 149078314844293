define('webshopCategoriesDetailBodySlugsView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'webshopCategoriesDetailBodySlugsLanguageView',
  'template!webshopCategoriesDetailBodySlugsView'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  LanguageView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    className: 'webshop-categories-detail-body-slugs-view',
    childView: LanguageView,
    childViewContainer: '.languages-container',
    childViewOptions: function () {
      return {
        product: this.model
      };
    },

    childEvents: {
      'change': 'changeSlug'
    },

    modelEvents: {
      'change:texts': 'fetch'
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
      this.webshopConfig = this.options.webshopConfig;
    },

    onShow: function () {
      this.fetch();
    },

    fetch: function () {
      this.collection.reset();
      _.each(this.options.webshopConfig.getLanguages(), _.bind(function (lang) {
        this.collection.add({
          slug: this.model.getSlug(lang),
          lang: lang,
          error: ''
        });
      }, this));
    }/*,

    changeSlug: function (view, value) {
      this.model.setSlug(view.model.get('lang'), value).done(_.bind(function () {
        if(view.ui.history.is(':visible') && view.historyView) {
          view.historyView.model.set('slug', value);
          view.historyView.reload();
          view.historyView.footerView.fetch();
        }
      }, this)).fail(_.bind(function (message) {
        view.model.set('error', message);
        this.model.fetch().done(_.bind(function () {
          _.each(this.collection.models, function (model) {
            model.set('slug', this.model.getSlug(model.get('lang')));
          }, this);
        }, this));
      }, this));
    }*/
  });
});
