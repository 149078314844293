define('ordersDetailSupplierEditProductPrintZoneView',[
  'app',
  'module',
  'marionette',
  'settings',
  'underscore',
  'pdfView',
  'ordersDetailSupplierEditProductPrintZoneImageView',
  'ordersDetailSupplierEditProductPrintZoneColorsView',
  'template!ordersDetailSupplierEditProductPrintZoneView'
], function (
  App,
  module,
  Marionette,
  Settings,
  _,
  PdfView,
  ImageView,
  ColorsView,
  Tpl
) {
  'use strict';

  module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'printzone-view',

    ui: {
      zoomButton: '.zoom-button',
      downloadButton: '.download-button'
    },

    regions: {
      imageRegion: '.image-region',
      colorsRegion: '.colors-region'
    },

    events: {
      'click @ui.downloadButton': 'onDownloadButtonClicked',
      'click @ui.zoomButton': 'onZoomButtonClicked'
    },

    serializeData: function() {
      var data = {};

      data.printAreaName = '-';
      if(this.model.get('printPositionNameEn'))
        data.printAreaName = this.model.get('printPositionNameEn');

      var printArea = _.findWhere(this.options.product.get('printAreas'), {code: this.model.get('position')});
      var printZone = null;

      if(printArea) {
        printZone = _.findWhere(printArea.printZones, {imppCode: this.model.get('printCode')});
        data.printAreaName = printArea.name;
      }

      data.print = '';
      data.productOwner = '';
      data.size = '';
      data.colors = '';
      data.colorModeType = '';
      data.imageUrl = '';

      if(this.model.get('files') && this.model.get('files').length > 0) {
        var file = this.model.get('files')[0];
        data.imageUrl = file.url;
        data.fileName = file.filename;
      }

      if(printZone) {
        if (printZone.printerName !== this.options.order.get('productOwner') && printZone.printerName !== undefined)
          data.productOwner = printZone.printerName;

        if (printArea.name)
          data.printAreaName = printArea.name;

        if(printZone.name)
          data.print = printZone.name;

        data.size = this.model.get('printWidth') + ' x ' + this.model.get('printHeight') + ' mm';

        data.colors = '';

        if (this.model.get('numberOfColors') !== undefined) {
          data.colors = this.model.get('numberOfColors');
          if (this.model.get('nbrColors') > 1)
            data.colors += ' ' + _.i18n('common.colors');
          else
            data.colors += ' ' + _.i18n('common.color');
        }

        data.colorModeType = printZone.colorModeType;
      }

      return data;
    },

    onRender: function () {
      this.imageView = new ImageView({
        model: this.model
      });
      this.getRegion('imageRegion').show(this.imageView);

      this.colorsView = new ColorsView({
        model: this.model
      });
      this.getRegion('colorsRegion').show(this.colorsView);
    },

    onZoomButtonClicked: function () {
      if(!this.model.get('files'))
        return;

      if(this.model.get('files').length === 0)
        return;

      var file = this.model.get('files')[0];

      PdfView.prototype.open({
        title: file.filename,
        url: file.url,
        height: '60%',
        width: '60%'
      });
    },

    onDownloadButtonClicked: function () {
      if(!this.model.get('files'))
        return;

      if(this.model.get('files').length === 0)
        return;

      var file = this.model.get('files')[0];

      var url = file.url;
      if(url === '')
        return;

      var fileName = file.fileName;

      var anchor = document.createElement('a');
      anchor.href = url;
      anchor.target = '_blank';
      anchor.download = fileName;
      anchor.click();
    }
  });
});
