define('mailsListMailLabelView',[
  'module',
  'windows',
  'marionette',
  'underscore',
  'template!mailsListMailLabelView'
], function (
  Module,
  Windows,
  Marionette,
  _,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,    
    className: 'label-view',

    serializeData: function () {
      return {
        name: this.model.get('name')
      };
    }
  });
});
  
