define('entities/mails',[
  'app',
  'jquery',
  'backbone',
  'underscore',
  'settings',
  'gmailUtils'
], function (
  App,
  $,
  Backbone,
  _,
  Settings,
  GmailUtils
) {
  'use strict';

  var app;
  if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
    app = {ns: Backbone.Relational.store._modelScopes[0]};
  } else {
    app = {ns: {}};
    Backbone.Relational.store.addModelScope(app.ns);
  }

  app.ns.Mail = Backbone.Model.extend({
    idAttribute: 'id',

    getAddress: function () {
      var address = '';

      if (this.get('from') && this.get('from').length > 0) {
        address = this.get('from')[0].address;
      }

      return address;
    },

    getName: function () {
      var name = '';

      if (this.get('from') && this.get('from').length > 0) {
        if (this.get('from')[0].personal)
          name = this.get('from')[0].personal;

        if (name === '')
          name = this.get('from')[0].address;
      }

      return name;
    },

    addLabel: function (fromSecId, folder, label) {
      var defer = $.Deferred();
      var url = Settings.url('compuzz2', 'mails/addLabel/' +
        fromSecId + '/' +
        this.get('id') +
        '/labels?label=' +
        encodeURIComponent(label)) + '&folder=' + encodeURIComponent(folder);

      $.ajax({
        method: 'POST',
        url: url,
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        },
        error: function () {
          defer.reject();
        },
        complete: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    removeLabel: function (fromSecId, label) {
      var defer = $.Deferred();
      var url = Settings.url('compuzz2', 'mails/addLabel/' +
        fromSecId + '/' +
        this.get('id') +
        '/labels?label=' + encodeURIComponent(label));

      $.ajax({
        method: 'DELETE',
        url: url,
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        },
        error: function () {
          defer.reject();
        },
        complete: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    getDowloadUrl: function (fromSecId, fileName) {
      var params = {
        folder: encodeURIComponent(this.get('folder'))
      };

      return Settings.url('compuzz2', 'mails/fetch/' +
        fromSecId + '/' +
        this.get('id') +
        '/download/' + encodeURIComponent(fileName), params);
    }
  });


  var API = {
    fromMail: [],

    getFoldersUrl: function (secId, params) {
      return Settings.url('compuzz2', 'mails/fetchFolders/' + secId, params);
    },

    getUrl: function (secId, params) {
      return Settings.url('compuzz2', 'mails/fetch/' + secId, params);
    },

    getMailUrl: function (secId, folder, mailId) {
      var params = {};
      params.folder = encodeURIComponent(folder);

      return Settings.url('compuzz2', 'mails/fetch/' + secId + '/' + mailId, params);
    },

    getFromMails: function () {
      var defer = $.Deferred();

      if (Settings.configValue('mails.remoteSmtp')) {
        if (API.fromMail.length > 0)
          defer.resolve(API.fromMail);
        else {
          $.ajax({
            type: 'GET',
            url: Settings.url('compuzz2', 'v2/userpid/emailAddresses'),
            contentType: 'application/json',
            success: function (mails) {
              API.fromMail = mails;
              defer.resolve(mails);
            }
          });
        }
      } else {
        var gmailUtils = GmailUtils.getInstance();
        var user = gmailUtils.getConnectedUser();
        if (user)
          defer.resolve([{secId: '', email: user}]);
      }

      return defer.promise();
    },

    getFromMail: function (params, locale) {

      if (Settings.configValue('mails.remoteSmtp')) {
        var from = '';

        if (!params)
          return from;

        if (params.def)
          from = params.def;

        if (locale && params[locale.toLowerCase()])
          from = params[locale.toLowerCase()];

        return from;
      } else {
        var gmailUtils = GmailUtils.getInstance();
        return gmailUtils.getConnectedUser();
      }
    },

    checkFrom: function (from, statusFromParams) {
      var defer = $.Deferred();
      if (!from) {

        if (!Settings.configValue('mails.remoteSmtp'))
          defer.reject();
        else
          defer.resolve();

        return defer.promise();
      }

      var found = false;

      if (Settings.configValue('mails.remoteSmtp')) {
        API.getFromMails().done(_.bind(function (mails) {
          found = false;
          _.each(mails, _.bind(function (mail) {
            if (mail.email === from)
              found = true;
          }, this));

          if (found)
            defer.resolve();
          else
            defer.reject();
        }, this));
      } else {
        found = false;
        var statusFrom = statusFromParams;
        if (statusFrom) {
          if (statusFrom === from)
            found = true;
        } else
          found = true;

        if (found)
          defer.resolve();
        else
          defer.reject();
      }

      return defer.promise();
    },

    sendMail: function (userSecId, to, cc, bcc, subject, message, attachments) {
      var defer = $.Deferred();

      if (Settings.configValue('mails.remoteSmtp')) {
        var data = {
          fromSecId: userSecId,
          to: to,
          cc: cc,
          bcc: bcc,
          subject: subject,
          message: message,
          attachments: attachments
        };

        $.ajax({
          type: 'POST',
          url: Settings.url('compuzz2', 'mails/send'),
          data: JSON.stringify(data),
          processData: false,
          contentType: 'application/json',
          success: function () {
            defer.resolve();
          },
          error: function () {
            defer.reject();
          }
        });
      } else {
        var gmailUtils = GmailUtils.getInstance();
        gmailUtils.sendMessage(to, cc, subject, message, attachments, function (result) {
          if (result && result.id)
            defer.resolve();
          else
            defer.reject();
        });
      }

      return defer.promise();
    },

    getUser: function () {
      var gmailUtils = GmailUtils.getInstance();
      return gmailUtils.getConnectedUser();
    }
  };

  App.reqres.setHandler('mails:model', function (data) {
    var tt;
    if (data) {
      tt = Backbone.Relational.store.find(app.ns.Mail, data.id);
    }
    if (tt) {
      return tt;
    } else {
      return new app.ns.Mail(data);
    }
  });

  App.reqres.setHandler('mails:getUser', function () {
    return API.getUser();
  });

  App.reqres.setHandler('mails:getFromMails', function () {
    return API.getFromMails();
  });


  App.reqres.setHandler('mails:send', function (userSecId, to, cc, bcc, subject, message, attachments) {
    return API.sendMail(userSecId, to, cc, bcc, subject, message, attachments);
  });

  App.reqres.setHandler('mails:getFromMail', function (params, locale) {
    return API.getFromMail(params, locale);
  });

  App.reqres.setHandler('mails:checkFrom', function (from, statusFromParams) {
    return API.checkFrom(from, statusFromParams);
  });

  App.reqres.setHandler('mails:get-folders-url', function (secId, params) {
    return API.getFoldersUrl(secId, params);
  });

  App.reqres.setHandler('mails:row-count-url', function (secId) {
    return API.getRowCountUrl(secId);
  });

  App.reqres.setHandler('mails:get-url', function (secId, params) {
    return API.getUrl(secId, params);
  });

  App.reqres.setHandler('mails:get-mail-url', function (secId, folder, mailId) {
    return API.getMailUrl(secId, folder, mailId);
  });
});
