
define('template!orderCompositionTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '\n<div class="proofsheet-region"></div>\n';
 if (exportFileUid) { ;
__p += '\n' +
((__t = ( _.i18n("order.customExport") )) == null ? '' : __t) +
' : <a href="' +
((__t = ( customExportUrl )) == null ? '' : __t) +
'">' +
((__t = ( purchaseOrderId )) == null ? '' : __t) +
'_artwork</a>\n    <i class=\'mdi-action-delete js-delete-custom-export clickable\'></i><br />\n';
 } ;
__p += '\n<br>\n\n<div class="row">\n    <div class="col-xs-6">\n        <label>' +
((__t = ( _.i18n("order.dtpComment") )) == null ? '' : __t) +
'</label>\n        <p class="order-product-comment">' +
((__t = ( comment.replace(/[\n]/g, '<br />') )) == null ? '' : __t) +
'</p>\n    </div>\n    <div class="col-xs-6">\n        <div class="row">\n            <label class="col-xs-12 p-l-0">' +
((__t = ( _.i18n("order.internalComment") )) == null ? '' : __t) +
'</label>\n        </div>\n        <div class="row">\n            <textarea class="col-xs-12 p-l-0 order-internal-comment" style="min-height: 80px;">' +
((__t = ( internalComment )) == null ? '' : __t) +
'</textarea>\n        </div>\n    </div>\n</div>\n<div class="row">\n    <div class="col-xs-12 js-print-area-container-region">\n    </div>\n</div>\n';
 if (notDesign2) { ;
__p += '\n    <div>\n        <h3>' +
((__t = ( _.i18n("common.add") )) == null ? '' : __t) +
'\n            <select class="paName" style="border:none">\n                <option value=""></option>\n                ';
 _.each(printAreas, function(printArea) { ;
__p += '\n                <option value="' +
((__t = ( printArea.id )) == null ? '' : __t) +
'">\n                    ' +
((__t = ( printArea.name )) == null ? '' : __t) +
' (' +
((__t = ( (printArea.width + "x" + printArea.height + "mm") )) == null ? '' : __t) +
')\n                </option>\n                ';
 }) ;
__p += '\n            </select>\n        </h3>\n    </div>\n';
 } ;
__p += '\n';

}
return __p
};});

